export const GetCityArea = {
  data() {
    return {
      city: {
        loading: false,
        data: []
      },
      area: {
        loading: false,
        data: []
      },
    }
  },
  methods: {
    getCity() {
      const vm = this
      const getCityApi = `${process.env.VUE_APP_API}/search/getPostalCodeData`;
      this.city = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'GET',
        async: true,
        url: getCityApi,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.city = {
              loading: false,
              data: res.data
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.city = {
            loading: false,
            data: ['請重新整理取得縣市資料']
          }
          alert(err.responseJSON)
        },
      })
    },
    getArea(city) {
      const vm = this
      const getAreaApi = `${process.env.VUE_APP_API}/search/getPostalCodeData?city=${city}`;
      this.area = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'GET',
        async: true,
        url: getAreaApi,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            let areaMap = new Map()
            res.data.forEach(item => {
              let Zip5 = item.Zip5
              if(!areaMap.has(item.Area)) {
                  areaMap.set(item.Area, Zip5)
              }
            })
            console.log(areaMap)
            let array = []
            areaMap.forEach((value, key) => {
              let object = {
                Area: key,
                Zip5: value
              }
              array.push(object)
            })
            vm.area = {
              loading: false,
              data: array
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.area = {
            loading: false,
            data: ['請重新整理取得縣市資料']
          }
          alert(err.responseJSON)
        },
      })
    },
  },
}