/**
 * 解析 token 並取的 participant
 * @param {*} storeId 
 * @param {*} serverToken 
 * @returns participant
 */
function parseTokenToGetTheParticipant(storeId, serverToken) {
  const getParticipantIdApi = `${process.env.VUE_APP_API}/participant/parseToken?storeId=${storeId}&token=${serverToken}`;
  const result = new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      async: true,
      url: getParticipantIdApi,
      success: resolve,
      error: reject,
    })
  })
  return result
}

export { parseTokenToGetTheParticipant }